import { render, staticRenderFns } from "./GameRecordMySearchBarComponent.vue?vue&type=template&id=474f0f39&scoped=true"
import script from "./GameRecordMySearchBarComponent.vue?vue&type=script&lang=js"
export * from "./GameRecordMySearchBarComponent.vue?vue&type=script&lang=js"
import style0 from "./GameRecordMySearchBarComponent.vue?vue&type=style&index=0&id=474f0f39&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474f0f39",
  null
  
)

export default component.exports