<template>
    <div>
        <game-record-my-search-bar-component
            :AllMode="AllMode"
            :AllIdentity="AllIdentity"
            :AllFinish="AllFinish"
            :AllIsDead="AllIsDead"
            @search="get_game_record_my_index"
        ></game-record-my-search-bar-component>
        <el-card class="box_card" v-loading.fullscreen.lock="loading">
            <el-table
                :data="table_data"
                style="width: 100%"
                border
                stripe
                highlight-current-row
            >
                <el-table-column
                    align="center"
                    type="index"
                    min-width="10px">
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="finish"
                    label="胜败"
                    min-width="60px"
                >
                    <template slot-scope="scope">
                        <img v-if="scope.row.finish === 1" :src="$store.state.baseURL+'icon/ying.png'" height="20px">
                        <img v-else :src="$store.state.baseURL+'icon/shu.png'" height="20px">
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="name"
                    label="玩家昵称"
                    min-width="150px"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span style="color: #921AFF;">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="使用角色"
                    min-width="150px"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.role }}</span>
                        <el-tag style="margin-left: 2px;" effect="dark" size="small" type="danger" v-if="(scope.row.is_dead === 1) && (scope.row.name === scope.row.murder)">自杀</el-tag>
                        <el-tag style="margin-left: 2px;" effect="dark" size="small" type="info" v-else-if="(scope.row.is_dead === 1) && (scope.row.name !== scope.row.murder)">死亡</el-tag>
                        <el-tag style="margin-left: 2px;" effect="dark" size="small" type="warning" v-else-if="scope.row.is_dead === 2">败露</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="identity"
                    label="阵营"
                    min-width="60px"
                >
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.identity === 0" effect="plain" size="small">军情</el-tag>
                        <el-tag v-else-if="scope.row.identity === 1" type="danger" effect="plain" size="small">潜伏</el-tag>
                        <el-tag v-else  type="success" effect="plain" size="small">酱油</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="mode"
                    label="模式"
                    min-width="60px"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.mode === 1">身份</span>
                        <span v-else-if="scope.row.mode === 2">3V3</span>
                        <span v-else-if="scope.row.mode === 3">酱油</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="user_num"
                    label="人数"
                    min-width="60px">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="杀人"
                    min-width="60px"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.kill_num" style="color: #FF0000;">{{ scope.row.kill_num }}</span>
                        <span v-else>{{ scope.row.kill_num }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="time"
                    label="对局时间"
                    min-width="170px">
                </el-table-column>
                <el-table-column
                    align="center"
                    label="查看"
                    min-width="70px"
                >
                    <template slot-scope="scope">
                        <el-button @click="detail(scope.row.time)" size="mini">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="total"
            @search="get_game_record_my_index"
        ></common-page-component>
        <game-record-edit-component
            :dialogFormVisible="detailDialogVisible"
            :AllMode="AllMode"
            :AllIdentity="AllIdentity"
            :AllFinish="AllFinish"
            :AllIsDead="AllIsDead"
            :DetailData="detailDialogDate"
            @exitGameRecordEditDialog="detailDialogVisible = false"
        ></game-record-edit-component>
    </div>
</template>

<script>
import { GameRecordMyIndexRequest,GameRecordDetailsRequest } from '@/network/query/GameRecord.js'

import GameRecordMySearchBarComponent from '@/components/query/GameRecord/GameRecordMySearchBarComponent'
import GameRecordEditComponent from '@/components/query/GameRecord/GameRecordEditComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'

export default {
    name: 'AuthTeamView',
    data() {
        return {
            loading: true,
            cond: {
                name: this.$store.state.user.name,
                role: '',
                mode:'',
                identity: '',
                finish: '',
                is_dead: '',
                starttime: '',
                endtime: '',
                page: 1,
                limit: 10
            },
            total: 0,
            table_data: [],
            detailDialogVisible: false,
            detailDialogDate: {
                detail: {
                    win: [],
                    lose: []
                }
            },
            AllMode:[
                { id: 1,name: '身份' },
                { id: 2,name: '3V3' },
                { id: 3,name: '酱油' },
            ],
            AllIdentity:[
                { id: 0,name: '军情' },
                { id: 1,name: '潜伏' },
                { id: 2,name: '酱油' }
            ],
            AllFinish:[
                { id: 0,name: '失败' },
                { id: 1,name: '胜利' }
            ],
            AllIsDead:[
                { id: 0,name: '存活' },
                { id: 1,name: '死亡' },
                { id: 2,name: '败露' },
            ]
        }
    },
    computed: {},
    methods: {
        get_game_record_my_index(param = {}) {

            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.role = param.role ?? this.cond.role
            this.cond.mode = param.mode ?? this.cond.mode
            this.cond.identity = param.identity ?? this.cond.identity
            this.cond.finish = param.finish ?? this.cond.finish
            this.cond.is_dead = param.is_dead ?? this.cond.is_dead
            this.cond.starttime = param.starttime ?? this.cond.starttime
            this.cond.endtime = param.endtime ?? this.cond.endtime
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            GameRecordMyIndexRequest(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.table_data = s.result.data
                        this.total = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })

                //滚动条回顶部
                this.$nextTick(() => {
                    document.getElementById('el_main').scrollTop = 0;
                })
        },
        detail(time) {
            this.loading = true
            GameRecordDetailsRequest({time: time})
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.detailDialogDate = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
            this.detailDialogVisible = true
        },
        exitGameRecordEditDialog() {
            this.detailDialogVisible = false
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_game_record_my_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        GameRecordMySearchBarComponent,
        CommonPageComponent,
        GameRecordEditComponent
    },
    watch: {},
}
</script>

<style lang='less' scoped>
.box-card .el-form-item {
    margin-bottom: 3px;
}
</style>